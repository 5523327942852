import SettingApi from "@/common/constants/setting";
import { SettingJson } from "@/types/Setting.type";
import { useQuery } from "@tanstack/react-query";
import { getSettingV2 } from "../api/shop/setting";

export default function useChildPromotionLabel({ isUse }: { isUse: boolean }) {
	const query = useQuery({
		queryKey: ["useChildPromotionLabel"],
		queryFn: async () => {
			const { data: response } = await getSettingV2(
				SettingApi.KEY.k_setting_child_promotion_label
			);
			return response as SettingJson;
		},
		enabled: isUse,
		staleTime: 60000,
		refetchOnMount: true,
		refetchOnWindowFocus: false,
	});

	return { ...query };
}
