"use client";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "@/redux/hook";

import { selectDevice } from "@/redux/features/ShopSlice";
import ProductConstants from "@/common/constants/product";
import { ProductJson, ProductTagJson } from "@/types/Product.type";
import ProductTags from "~components/product/ProductTags";
import WishlistBtn from "~components/wishlist/WishlistBtn";
import {
	CardBadge,
	CardContent,
	CardImage,
	CardImages,
	CardRating,
	CardWarper,
} from "@/app/_components/card";
import {
	ProductCardQuickView,
	ProductCardBrand,
	ProductCardPrice,
} from "./productCardchildren";
import LinkElement from "@/components/LinkElement";
import useDealthom from "@/lib/hooks/useDealthom";

import { PricePromotionJson } from "@/types/Cart.type";
import AddCircleRed from "@/components/icons/AddCircleRed";
import ProductAddCartPopupMobile from "./productCardchildren/ProductAddCartPopupMobile";
import ProductPopup from "~components/collection/children/ProductPopup";
import PopupV2 from "@/components/PopupV2";
import { checkIsPromotionProduct, getPromotionValid } from "@/utils/product";
import { convertPromotionChild } from "@/utils/promotion";
import useChildPromotionLabel from "@/lib/hooks/useChildPromotionLabel";

export type ProductCardProps = {
	data: ProductJson;
	showCountChild?: boolean;
	showWishlist?: boolean;
	collectionHandle?: string;
	sliderImgMobile?: boolean;
};

export default function ProductCard({
	data,
	showCountChild,
	showWishlist = true,
	collectionHandle,
	sliderImgMobile,
}: ProductCardProps) {
	const device = useAppSelector(selectDevice);
	const { promotionsHaveConfig } = useDealthom(data);

	// const settingDealthom = useAppSelector(getSettingDealthom);

	const [open, setOpen] = useState(false);

	//////////////////////////////////////
	const resize_image =
		device === "desktop"
			? ProductConstants.RESIZE_CARD_IMAGE_PRODUCT_DESKTOP
			: ProductConstants.RESIZE_CARD_IMAGE_PRODUCT_MOBILE;

	const nameShow = data.parent_id === 0 ? data.name : data.full_name;

	const promotionActive = promotionsHaveConfig.filter((item) =>
		checkIsPromotionProduct(item)
	)?.[0];

	const promotionChild = convertPromotionChild(data.child_promotions);

	const priceReview: PricePromotionJson | null = promotionActive
		? {
				product_id: data.id,
				discount: promotionActive.compare_discount,
				item_quantity: 1,
				price_final: promotionActive.promotion_price,
				price_Unit: promotionActive.promotion_price,
		  }
		: data.pricePreview;

	//////////////////////////////////////
	const onlineOnly = useMemo(() => {
		let result: ProductTagJson | null = null;
		if (data.tags.length > 0) {
			const tagData = data.tags.find((t) => t.code === "only");
			if (tagData) {
				result = tagData;
			}
		}
		return result;
	}, [data]);

	const { data: settingChildPromotionLabel } = useChildPromotionLabel({ isUse: promotionChild.promotions.length > 0 });

	const tagPromotion: ProductTagJson[] =
		promotionsHaveConfig.length
			? [
					{
						code: "sale-promotion",
						description: "",
						id: 0,
						name: promotionsHaveConfig?.[0]?.label?.name_card || "sale",
						type: "PRODUCT CARD",
						value: promotionsHaveConfig?.[0]?.label?.name_card || "sale",
						// style: {
						// 	bg_color: tag.bg_color,
						// 	color: tag.text_color,
						// },
					},
			  ]
			:
		promotionChild.promotions.length
			? [
					{
						code: "sale-promotion",
						description: "",
						id: 0,
						name: settingChildPromotionLabel?.value || "sale",
						type: "PRODUCT CARD",
						value: settingChildPromotionLabel?.value || "sale",
					}
			  ]
			: [];

	return (
		<>
			<CardWarper className="group h-full">
				<div className="relative flex-shrink-0">
					{!sliderImgMobile ? (
						<LinkElement
							prefetch={false}
							href={{
								pathname: `/products/${
									collectionHandle ? collectionHandle + "/" : ""
								}${data.handle}`,
							}}
							className="md:w-full h-[183px] w-[183px] md:h-auto block">
							<CardImage
								src={data.images}
								resize={{ height: resize_image, width: resize_image }}
								alt={nameShow}
							/>
						</LinkElement>
					) : (
						<>
							<div className="md:hidden card_images ">
								<CardImages
									handle={data.handle}
									collectionHandle={collectionHandle}
									src={data.images}
									resize={{ height: resize_image, width: resize_image }}
									alt={nameShow}
								/>
							</div>

							<div className=" hidden md:block">
								<LinkElement
									prefetch={false}
									href={{
										pathname: `/products/${
											collectionHandle ? collectionHandle + "/" : ""
										}${data.handle}`,
									}}
									className=" md:w-full flex justify-center  ">
									<CardImage
										src={data.images}
										resize={{ height: resize_image, width: resize_image }}
										alt={nameShow}
									/>
								</LinkElement>
							</div>
						</>
					)}
					<ProductCardQuickView
						product={data}
						className="opacity-0 group-hover:opacity-100 absolute bottom-0 left-0 right-0"
					/>
				</div>
				<CardContent className="flex-1 justify-start">
					<div className="md:h-[16px] h-[19px]">
						<p className="text-gray-500 font-semibold md:font-medium md:text-xs text-sm  leading-4 line-clamp-1">
							{onlineOnly && onlineOnly.name}
							{/* online only */}
						</p>
					</div>
					<ProductCardBrand brand={data.brand} />
					<LinkElement
						data-id={data.id}
						href={{
							pathname: `/products/${
								collectionHandle ? collectionHandle + "/" : ""
							}${data.handle}`,
						}}
						className="w-full text-gray-500 h-[26px] leading-[13px] md:h-[38px] md:leading-[1.6] capitalize text-[12px] md:text-sm hover:text-yellow-500 hover:underline transition-all duration-300 cursor-pointer line-clamp-2 ">
						{nameShow}
					</LinkElement>
					<ProductCardPrice product={data} pricePreview={priceReview} />
					{/* <ProductCardPrice product={data} /> */}

					{/* {showCountChild && (
					<ProductCardCountChilds count={data?.count_childs} />
				)} */}
					<div className=" flex justify-between">
						{data?.rate?.rate ? (
							<CardRating rate={data.rate.rate} />
						) : (
							<div></div>
						)}

						<div
							className=" w-6 h-6   block md:hidden"
							onClick={() => {
								setOpen(true);
							}}>
							<AddCircleRed />
						</div>
					</div>
				</CardContent>
				<CardBadge>
					<ProductTags
						tags={[...tagPromotion, ...data?.tags]}
						// tags={[...data?.tags]}
						className="flex flex-col gap-1"
						itemClassName="px-[5px] py-[2px] h-[16px] capitalize"
						type="PRODUCT CARD"
					/>
				</CardBadge>
				{showWishlist && (
					<CardBadge position="right" className="top-[8px]">
						<WishlistBtn
							model={{
								item_id: data.parent_id > 0 ? data.parent_id : data.id,
								item_type: 1,
								item_url: data.handle,
							}}
						/>
					</CardBadge>
				)}
			</CardWarper>

			<PopupV2
				open={open}
				onOpenChange={() => setOpen(false)}
				animate="tran-up"
				classNameContent="px-4">
				<ProductPopup
					product={data}
					handleAddCartSuccecss={() => setOpen(false)}
					onCLose={() => setOpen(false)}
				/>
			</PopupV2>
		</>
	);
}
